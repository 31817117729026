import { default as g_45oauth_45redirectzuhR7ZyK0cMeta } from "/app/pages/auth/g-oauth-redirect.vue?macro=true";
import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as instagram_45status3wrCc8WInrMeta } from "/app/pages/connect/instagram-status.vue?macro=true";
import { default as instagramKdrWDqSvieMeta } from "/app/pages/connect/instagram.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as membership_45plans9sSB27dGFAMeta } from "/app/pages/membership-plans.vue?macro=true";
import { default as listn0OcoOjUFhMeta } from "/app/pages/pilots-scenarios/list.vue?macro=true";
import { default as statisticf53tmHqLWsMeta } from "/app/pages/statistic.vue?macro=true";
export default [
  {
    name: "auth-g-oauth-redirect",
    path: "/auth/g-oauth-redirect",
    meta: g_45oauth_45redirectzuhR7ZyK0cMeta || {},
    component: () => import("/app/pages/auth/g-oauth-redirect.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    component: () => import("/app/pages/auth/login.vue")
  },
  {
    name: "connect-instagram-status",
    path: "/connect/instagram-status",
    meta: instagram_45status3wrCc8WInrMeta || {},
    component: () => import("/app/pages/connect/instagram-status.vue")
  },
  {
    name: "connect-instagram",
    path: "/connect/instagram",
    meta: instagramKdrWDqSvieMeta || {},
    component: () => import("/app/pages/connect/instagram.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "membership-plans",
    path: "/membership-plans",
    meta: membership_45plans9sSB27dGFAMeta || {},
    component: () => import("/app/pages/membership-plans.vue")
  },
  {
    name: "pilots-scenarios-list",
    path: "/pilots-scenarios/list",
    meta: listn0OcoOjUFhMeta || {},
    component: () => import("/app/pages/pilots-scenarios/list.vue")
  },
  {
    name: "statistic",
    path: "/statistic",
    meta: statisticf53tmHqLWsMeta || {},
    component: () => import("/app/pages/statistic.vue")
  }
]
export const DATABASE = "user_content";
export const PROFILE_COLLECTION = "user-profile";

// INSTAGRAM MODULE
export const INSTAGRAM_DATABASE = DATABASE;
export const INSTAGRAM_CONNECTION = "insta_connection";
export const INSTAGRAM_PROFILE = "insta_profile";

// pilots MODULE
export const PILOTS_DATABASE = DATABASE;
export const PILOTS_SCENARIOS_COLLECTION = "pilots-scenarios";

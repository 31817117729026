import { useInstagramAdapterStore } from '~/stores/instagram-adapter';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const instagramAdapter = useInstagramAdapterStore();

  const config = useRuntimeConfig();
  if (config.public.isNotProduction) return;

  // Fetch profiles if they haven't been fetched yet
  //
  if (instagramAdapter.totalProfiles === 0) {
    await instagramAdapter.fetchProfiles();
  }

  // Redirect to connect page if no profiles are found
  //
  if (instagramAdapter.totalProfiles === 0) {
    return navigateTo('/connect/instagram');
  }
});
